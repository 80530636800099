.app__header {
    background: url('/src/assets/bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-weight: 700;
    line-height: 83.2px;
    font-size: 64px;
}

.app__header-img img {
    width: 80%;
}

/*@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 150px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 60px;
        line-height: 87px;
    }
}*/

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 40px;
        line-height: 70px;
    }
}
